<template>
  <div id="login">
    <slot />
  </div>
</template>

<style>
#login {
  font-family: 'Neue Haas Grotesk Disp', 'Inter', Helvetica, Arial, sans-serif;
}
</style>
